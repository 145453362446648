import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles, Box } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #2E3542',
    color: '#2E3542',
    margin: '0 4px 0 4px',

    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#E41139',
      color: '#E41139',
    },
  },
}));

const CapsuleCardActions = (props) => {
  const classes = useStyles();
  const { capsule, onDelete } = props;

  return (
    <Box>
      <IconButton className={classes.root} component={Link} to={'/capsules/' + capsule.capsuleId + '/view'}>
        <VisibilityOutlinedIcon />
      </IconButton>

      {capsule.allowChange === 0 || ['UD', 'ND', 'PA', 'PE', 'E'].includes(capsule.status) ? null : (
        <IconButton className={classes.root} component={Link} to={'/capsules/' + capsule.capsuleId + '/edit'}>
          <EditOutlinedIcon />
        </IconButton>
      )}

      {capsule.allowDelete === 0 || ['UD', 'ND', 'PA', 'PE'].includes(capsule.status) ? null : (
        <IconButton className={classes.root} onClick={onDelete}>
          <DeleteOutlinedIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default CapsuleCardActions;
