import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './themes/light.theme';
import reportWebVitals from './reportWebVitals';
import './i18n';
import App from './containers/App';
import { ProvideApi } from './hooks/useApi';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideSteps } from './hooks/useSteps';

ReactDOM.render(
  <ProvideAuth>
    <ProvideApi>
      <ProvideSteps>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ProvideSteps>
    </ProvideApi>
  </ProvideAuth>,
  document.getElementById('root')
);

reportWebVitals();

console.log(`

███████╗ █████╗ ██╗   ██╗███████╗████████╗ ██████╗ ███████╗██╗   ██╗████████╗██╗   ██╗██████╗ ███████╗
██╔════╝██╔══██╗██║   ██║██╔════╝╚══██╔══╝██╔═══██╗██╔════╝██║   ██║╚══██╔══╝██║   ██║██╔══██╗██╔════╝
███████╗███████║██║   ██║█████╗     ██║   ██║   ██║█████╗  ██║   ██║   ██║   ██║   ██║██████╔╝█████╗  
╚════██║██╔══██║╚██╗ ██╔╝██╔══╝     ██║   ██║   ██║██╔══╝  ██║   ██║   ██║   ██║   ██║██╔══██╗██╔══╝  
███████║██║  ██║ ╚████╔╝ ███████╗   ██║   ╚██████╔╝██║     ╚██████╔╝   ██║   ╚██████╔╝██║  ██║███████╗
╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝   ╚═╝    ╚═════╝ ╚═╝      ╚═════╝    ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
                                                                                                      
`);
