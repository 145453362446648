import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import TooltipIcon from '../../components/TooltipIcon';
import { TargetBox } from '../../components/TargetBox';
import { useSteps } from '../../hooks/useSteps';
import { useApi } from '../../hooks/useApi';
import {
  decryptAesKey,
  encryptFileWithAes,
  generateRsaKeys,
  importAesKey,
  readFileAsBuffer,
} from '../../helpers/crypto';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  fileStatus: {
    color: '#C1C4C9',
    fontSize: 20,
    padding: 12,
    whiteSpace: 'nowrap',
  },
  linearProgress: {
    minWidth: 100,
    height: 6,
    backgroundColor: '#E7EBF1',
    borderRadius: 8,
    marginTop: 10,
  },
  actionText: {
    marginTop: 14,
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
}));

/**
 * Capsule creation: Step 2
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateCapsuleStepFiles = (props) => {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [files, setFiles] = useState(capsule.files || []);
  const [phase, setPhase] = useState();
  const [isProcessing, setProcessing] = useState(false);
  const [tooManyFiles, setTooManyFiles] = useState(false);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  const closeSnackbar = () => {
    setTooManyFiles(false);
    setFileSizeExceeded(false);
  };

  const fileStatus = (status, percentCompleted) => {
    switch (status) {
      case 'encrypting':
        return (
          <Box>
            {'encrypting '} <CircularProgress size={16} color="secondary" />
          </Box>
        );
      case 'uploading':
        return (
          <Box>
            <LinearProgress color="secondary" className={classes.linearProgress} />
          </Box>
        );
      case 'error':
        return (
          <Box>
            <p style={{ color: '#B00020' }}>{'uploading error'}</p>
          </Box>
        );
      default:
        return null;
    }
  };

  const isFilesReady = (files) => {
    // eslint-disable-next-line array-callback-return
    return files.filter((file) => {
      if (file.ready === true) {
        return file;
      }
    });
  };

  const encryptFile = async (file, filename) => {
    let status = false;
    let addFileData;
    let formData = new FormData();

    try {
      const keyPair = await generateRsaKeys();

      addFileData = await api.addFile({
        capsuleId: capsule.capsuleId,
        clientFileKey: keyPair.publicKey,
      });

      const { aesKey, aesIv } = decryptAesKey(keyPair.privateKey, addFileData.data.fileKeyEnc);
      const aesCryptoKey = await importAesKey(aesKey);
      const encryptedFile = await encryptFileWithAes(aesCryptoKey, aesIv, file);

      formData.append('fileId', addFileData.data.fileId);
      formData.append('capsuleId', capsule.capsuleId);
      formData.append('file', new Blob([encryptedFile]), filename);
    } catch (error) {
      status = fileStatus('error');

      return {
        status: status,
      };
    }

    return {
      form: formData,
      fileId: addFileData.data.fileId,
    };
  };

  const readFiles = async (files) => {
    const promises = files.map(async (item) => {
      const buffer = await readFileAsBuffer(item);

      return {
        ...item,
        buffer,
        phase: 2,
        ready: false,
        status: fileStatus('encrypting'),
      };
    });

    return await Promise.all(promises);
  };

  const uploadFiles = async (filesToUpload) => {
    const promises = filesToUpload.map(async (item) => {
      try {
        await api.uploadFile(item.form, (progressEvent) => {
          // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          //
          // const filesWithUploadStatus = files.map((file) => {
          //   if (item.index === file.index) {
          //     return { ...file, status: fileStatus('uploading', percentCompleted) };
          //   }
          //   return file;
          // });
          //
          // setFiles(filesWithUploadStatus);
        });

        return {
          ...item,
          phase: 4,
          status: 'done',
          ready: true,
        };
      } catch (e) {
        return {
          ...item,
          phase: 4,
          status: fileStatus('error'),
          ready: false,
        };
      }
    });

    return await Promise.all(promises);
  };

  const encryptFiles = async (files) => {
    const promises = files.map(async (item) => {
      const { form, fileId, status } = await encryptFile(item.buffer, item.file.name);

      return {
        ...item,
        form,
        fileId,
        filename: item.file.name,
        phase: 3,
        ready: false,
        status: status ? status : fileStatus('uploading'),
      };
    });

    return await Promise.all(promises);
  };

  const updateFiles = (originalFiles, changedFiles) => {
    return originalFiles.map((oFile) => {
      // eslint-disable-next-line array-callback-return
      changedFiles.map((cFile) => {
        if (cFile.index === oFile.index && !oFile.ready) {
          oFile = cFile;
        }
      });

      return oFile;
    });
  };

  const isFileSizeExceeded = (eventFiles) => {
    const eventFileSize = eventFiles.reduce((totalSize, file) => totalSize + file.size / (1024 * 1024), 0);
    const existingFilesSize = files.reduce((totalSize, item) => totalSize + item.file.size / (1024 * 1024), 0);
    return eventFileSize + existingFilesSize > 1000;
  };

  const handleFileInputChange = async (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length && selectedFiles.length + files.length > 100) {
      setTooManyFiles(true);
    } else if (isFileSizeExceeded(Array.from(selectedFiles))) {
      setFileSizeExceeded(true);
    } else {
      const advancedFiles = Array.from(selectedFiles).map((file, index) => {
        return {
          index: files.length + index,
          file,
          status: 'waiting upload',
          ready: false,
          phase: 1,
        };
      });

      setFiles([...files, ...advancedFiles]);
      setPhase(1);
    }
  };

  const handleFileDrop = async (item, monitor) => {
    if (monitor) {
      const selectedFiles = await monitor.getItem().files;

      if (selectedFiles.length && selectedFiles.length + files.length > 100) {
        setTooManyFiles(true);
      } else if (isFileSizeExceeded(selectedFiles)) {
        setFileSizeExceeded(true);
      } else {
        const advancedFiles = selectedFiles.map((file, index) => {
          return {
            index: files.length + index,
            file,
            status: 'waiting upload',
            ready: false,
            phase: 1,
          };
        });

        setFiles([...files, ...advancedFiles]);
        setPhase(1);
      }
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = files;
    const fileToDelete = updatedFiles[index];

    console.log(fileToDelete);

    api
      .deleteFile({ fileId: fileToDelete.fileId || fileToDelete.file.id, capsuleId: capsule.capsuleId })
      .then(() => {
        updatedFiles.splice(index, 1);
        setFiles([...updatedFiles]);
        setCapsule({ ...capsule, files: updatedFiles });
      })
      .catch((error) => {
        if (error.response.data === 1) {
          updatedFiles.splice(index, 1);
          setFiles([...updatedFiles]);
        }
      });
  };

  const processFiles = (phase) => {
    const unprocessedFiles = files.filter((file) => !file.ready);

    const nextPhase = (phase, files, updates) => {
      setFiles(updateFiles(files, updates));
      setPhase(phase);
    };

    switch (phase) {
      case 1:
        setProcessing(true);
        readFiles(unprocessedFiles).then((updates) => {
          nextPhase(2, files, updates);
        });
        break;
      case 2:
        encryptFiles(unprocessedFiles).then((updates) => {
          nextPhase(3, files, updates);
        });
        break;
      case 3:
        uploadFiles(unprocessedFiles).then((updates) => {
          nextPhase(4, files, updates);
          setProcessing(false);
        });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    processFiles(phase);
    setCapsule({ ...capsule, files: [...files], stepCover: 2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/files' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 600 }}>
            {t('translation:createCapsule:capsuleFilesStep:uploadLabel')}{' '}
            <TooltipIcon title={t('translation:createCapsule:capsuleFilesStep:uploadTooltip')} placement="top" />
          </Typography>

          <Box mt={3} mb={3}>
            <Button component="label" variant="contained" color="default" endIcon={<CloudUploadIcon />}>
              {t('translation:common:upload')}
              <input type="file" multiple style={{ display: 'none' }} onChange={handleFileInputChange} />
            </Button>
          </Box>
        </Grid>

        <DndProvider backend={HTML5Backend}>
          <Grid item xs={12} sm={12}>
            <TargetBox onDrop={handleFileDrop}>
              <List>
                {files.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar style={{ minWidth: 32 }}>
                      <InsertDriveFileOutlinedIcon />
                    </ListItemAvatar>

                    <ListItemText>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                          {item.file.name}
                        </Box>
                        <Box className={classes.fileStatus}>
                          {item.status === 'done' ? (item.file.size / (1024 * 1024)).toFixed(2) + ' Mb' : item.status}
                        </Box>
                      </Box>
                    </ListItemText>

                    <ListItemSecondaryAction>
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          edge="end"
                          onClick={() => {
                            handleFileDelete(index);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon style={{ color: '#C1C4C9' }} />
                        </IconButton>
                      </Box>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </TargetBox>
          </Grid>
        </DndProvider>

        <Grid item xs={12} sm={12}>
          <Box mt={8}>
            <Button
              variant="outlined"
              size="large"
              disabled={isProcessing}
              style={{ marginRight: 16 }}
              onClick={props.handleBack}
            >
              {t('translation:common:back')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!isFilesReady(files).length || isProcessing}
              onClick={props.handleNext}
            >
              {t('translation:common:next')}
            </Button>
          </Box>
          <Typography className={classes.actionText}>
            {isProcessing
              ? t('translation:createCapsule:capsuleFilesStep:actionDescription1')
              : t('translation:createCapsule:capsuleFilesStep:actionDescription2')}
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={tooManyFiles || fileSizeExceeded}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {tooManyFiles
            ? t('translation:createCapsule:capsuleFilesStep:tooManyFiles')
            : t('translation:createCapsule:capsuleFilesStep:filesSizeExceeded')}
        </Alert>
      </Snackbar>
    </Box>
  );
};
