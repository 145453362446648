import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import 'react-circular-progressbar/dist/styles.css';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';
import { useRouter } from '../../hooks/useRouter';

import ServiceStatusWidget from './ServiceStatusWidget';
import ServiceUptimeWidget from './ServiceUptimeWidget';
import PaymentsProcessingStatusWidget from './PaymentsProcessingStatusWidget';
import ProcessingStatusWidget from './ProcessingStatusWidget';
import CoreVersionWidget from './CoreVersionWidget';
import CapsulesCountWidget from './CapsulesCountWidget';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
}));

const style = {
  backgroundColor: '#747D8D',
  padding: '1px 16px',
  borderRadius: 10,
  color: '#FFF',
  fontSize: 20,
  fontWeight: 500,
};

const Dashboard = () => {
  const classes = useStyles();
  const auth = useAuth();
  const api = useApi();
  const { t } = useTranslation();
  const [capsulesCount, setCapsulesCount] = useState(null);
  const [totalCapsules, setTotalCapsules] = useState(0);
  const [serviceStatus, setServiceStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const router = useRouter();

  const init = async () => {
    setLoading(true);
    try {
      const capsules = await api.getCapsulesCount();
      const status = await api.getServiceStatus();

      const capsulesValues = Object.values(capsules.data);
      const total = capsulesValues.length
        ? capsulesValues.reduce((previousValue, currentValue) => previousValue + currentValue)
        : 0;
      setTotalCapsules(total);

      setCapsulesCount(capsules.data);
      setServiceStatus(status.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isReady) {
      init().then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Container className={classes.container} maxWidth="lg">
      <Typography component="div">
        <Box mb={2} fontSize={20} fontWeight={600}>
          {t('translation:dashboard:title')}
        </Box>
      </Typography>

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={1}>
                <Box p={3} style={{ backgroundColor: '#515C70', borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
                  <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                      <Box>
                        <Typography component="div" style={{ color: '#FFF' }}>
                          <Box fontSize={24} fontWeight={500}>
                            {t('translation:dashboard:capsules:title')}
                          </Box>
                        </Typography>

                        <Box display="flex" alignItems="center">
                          <Typography component="div" style={{ color: '#FFF' }}>
                            <Box fontSize={20}>{t('translation:dashboard:capsules:total')}</Box>
                          </Typography>

                          <Box ml={2} style={style}>
                            {totalCapsules}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          component={Link}
                          to={{
                            pathname: '/create-capsule',
                            state: { from: router.location.pathname },
                          }}
                          style={{ backgroundColor: '#FFF' }}
                        >
                          <AddIcon />
                          {t('translation:dashboard:capsules:createCapsule')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box p={3}>
                  <Grid container spacing={2} direction="row" alignItems="stretch">
                    <Grid item xs={12} sm={6} md={4}>
                      <CapsulesCountWidget
                        title={t('translation:dashboard:capsules:capsulesStored')}
                        status="P"
                        count={capsulesCount?.P || 0}
                        color="#80D948"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CapsulesCountWidget
                        title={t('translation:dashboard:capsules:capsulesUnpaid')}
                        status="N"
                        count={capsulesCount?.N || 0}
                        color="#E41239"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CapsulesCountWidget
                        title={t('translation:dashboard:capsules:capsulesIncomplete')}
                        status="E"
                        count={capsulesCount?.E || 0}
                        color="#4984BF"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CapsulesCountWidget
                        title={t('translation:dashboard:capsules:capsulesDeleted')}
                        status="UD"
                        count={(capsulesCount?.UD || 0) + (capsulesCount?.ND || 0)}
                        color="#B7BECE"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <CapsulesCountWidget
                        title={t('translation:dashboard:capsules:capsulesOpened')}
                        status="PE"
                        count={(capsulesCount?.PE || 0) + (capsulesCount?.PA || 0)}
                        color="#0064C2"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={500}>
              {t('translation:dashboard:status:title')}
            </Box>
          </Typography>

          <Grid container spacing={3}>
            <Grid item container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <ServiceStatusWidget serviceStatus={serviceStatus?.serviceStatus} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <ServiceUptimeWidget serviceUptime={serviceStatus?.uptime} />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {serviceStatus ? (
                  <PaymentsProcessingStatusWidget
                    cardStatus={serviceStatus?.payCard}
                    bitcoinStatus={serviceStatus?.payBtc}
                    ethereumStatus={serviceStatus?.payEth}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {serviceStatus ? (
                <ProcessingStatusWidget
                  messageBackground={serviceStatus?.msgBackground}
                  messageText={serviceStatus?.msgTextEn}
                />
              ) : null}
            </Grid>

            <Grid item xs={6}>
              {serviceStatus ? <CoreVersionWidget coreVersion={serviceStatus?.coreVersion} /> : null}
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Dashboard;
