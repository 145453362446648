export const ROOT = '/';

export const AUTH = '/authentication';
export const AUTH_SIGN_UP = '/authentication?mode=signUp';
export const AUTH_SIGN_IN = '/authentication?mode=signIn';
export const AUTH_FORGOT_PASSWORD = '/authentication?mode=forgotPassword';
export const AUTH_RESET_PASSWORD = '/authentication?mode=resetPassword';
export const AUTH_VERIFY_EMAIL = '/authentication?mode=verifyEmail';
export const AUTH_VERIFY_PLEASE = '/authentication?mode=verifyPlease';

export const USER_DASHBOARD = '/dashboard';
export const USER_CAPSULES = '/capsules';
export const USER_CAPSULE_VIEW = '/capsules/:id/view';
export const USER_CAPSULE_EDIT = '/capsules/:id/edit';
export const USER_CREATE_CAPSULE = '/create-capsule';
export const USER_CREATE_CAPSULE_CONTINUE = '/create-capsule/:id';
export const USER_PROFILE = '/profile';
export const USER_PROFILE_TOP_UP_BALANCE = '/profile/top-up-balance';
export const USER_PROFILE_PAYMENTS_HISTORY = '/profile/payments-history';
export const USER_PRICING = '/pricing';
export const USER_FAQ = '/faq';
export const USER_SUPPORT = '/support';
export const USER_PRIVACY_POLICY = '/privacy-policy';
export const USER_TERMS_OF_SERVICE = '/terms-of-service';
export const USER_PAY = '/pay/:id';
export const USER_CAPSULE_PROLONG = '/prolong/:id';
