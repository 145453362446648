import React, { createContext, useContext, useState } from 'react';

const StepsContext = createContext(null);

export function ProvideSteps({ children }) {
  const steps = useProvideSteps();
  return <StepsContext.Provider value={steps}>{children}</StepsContext.Provider>;
}

export const useSteps = () => {
  return useContext(StepsContext);
};

const INITIAL_STATE = {
  capsuleId: null,
  capsuleName: '',
  storePeriod: 0,
  files: [],
  activationEvent: 1,
  emailActivationCode: '',
  actionType: 1,
  addrEmail: '',
  addrWhatsApp: '',
  addrTelegram: '',
  addrFacebook: '',
  courierService: '',
  courierAddress: '',
  allowDelete: 1,
  allowChange: 1,
  hideNames: 0,
  paymentMethod: 0,
};

function useProvideSteps() {
  const [capsule, setCapsule] = useState(INITIAL_STATE);
  const [sealedCapsule, setSealedCapsule] = useState(null);

  return {
    INITIAL_STATE,
    capsule,
    sealedCapsule,
    setCapsule,
    setSealedCapsule,
  };
}
