import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuth } from '../../hooks/useAuth';
import Backdrop from '@material-ui/core/Backdrop';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    margin: theme.spacing(0, 0, 10, 0),
  },
  title: {
    margin: theme.spacing(0, 0, 4.5, 0),
  },
  subtitle: {
    maxWidth: 600,
  },
  input: {
    fontSize: 24,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 0, 0),
  },
  error: {
    color: theme.palette.error.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const VerifyPlease = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [isLoading, setLoading] = useState(false);

  const onResendLinkButtonClick = () => {
    setLoading(true);
    setError(null);

    auth.user
      .sendEmailVerification()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const onChangeNewEmail = (event) => {
    setNewEmail(event.target.value);
  };

  console.log(email);

  useEffect(() => {
    document.title = 'Verify please - SaveToFuture';
    setEmail(auth.user?.email);

    return () => {};
  }, [auth]);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.header} textAlign="center">
        <Box className={classes.title}>
          <Typography component="h1" variant="h4" style={{ fontWeight: 600 }}>
            {t('translation:auth:verifyPlease:title')}
          </Typography>
        </Box>

        <Box>
          <Box className={classes.subtitle}>
            <Typography component="h2" variant="h5">
              {t('translation:auth:verifyPlease:subtitle1', {
                email: email,
              })}
            </Typography>
          </Box>

          {email ? null : (
            <Box display="flex" justifyContent="center" mt={3}>
              <TextField
                type="email"
                id="email"
                name="email"
                value={newEmail}
                color="primary"
                onChange={onChangeNewEmail}
                placeholder={t('translation:auth:signIn:inputEmailPlaceholder')}
                required
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Box>
          )}

          <Box className={classes.subtitle} mt={5}>
            <Typography component="h2" variant="h5" style={{ fontWeight: 600 }}>
              {t('translation:auth:verifyPlease:subtitle2')}
            </Typography>
          </Box>

          <Box className={classes.subtitle} mt={1}>
            <Typography component="h2" variant="h5">
              {t('translation:auth:verifyPlease:checkSpam')}
            </Typography>
          </Box>
        </Box>

        <Box textAlign="center" mt={4} mb={4}>
          {error && (
            <Typography component="p" variant="body1" className={classes.error}>
              {error.message}
            </Typography>
          )}
        </Box>

        <Box mt={4} mb={5.5}>
          <Button color="secondary" size="large" onClick={onResendLinkButtonClick}>
            {!isLoading ? (
              t('translation:auth:verifyPlease:resendButton')
            ) : (
              <CircularProgress color="inherit" size={26} />
            )}
          </Button>
        </Box>

        <Box textAlign="center">
          <Typography component="p" variant="body1">
            {t('translation:auth:verifyPlease:haveAccount')}{' '}
            <Button color="secondary" component={Link} to="/authentication?mode=signIn">
              {t('translation:auth:verifyPlease:signInLabel')}
            </Button>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default VerifyPlease;
