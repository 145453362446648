import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';

import iconOperationalGreen from '../../assets/icon-operational-green.png';
import iconOperationalYellow from '../../assets/icon-operational-yellow.png';
import iconOperationalRed from '../../assets/icon-operational-red.png';
import { useTranslation } from 'react-i18next';

const styles = {
  paper: { height: '100%', display: 'flex' },
  title: { color: '#A6AAB0' },
};

const ServiceStatusWidget = (props) => {
  const { serviceStatus } = props;
  const { t } = useTranslation();
  let serviceStatusIcon;

  switch (serviceStatus) {
    case 0:
      serviceStatusIcon = iconOperationalRed;
      break;
    case 1:
      serviceStatusIcon = iconOperationalGreen;
      break;
    case 2:
      serviceStatusIcon = iconOperationalYellow;
      break;
    default:
      serviceStatusIcon = null;
  }

  return serviceStatus !== undefined ? (
    <Paper elevation={1} style={styles.paper}>
      <Box p={4} display="flex" alignItems="center">
        <Box mr={4}>
          {serviceStatusIcon ? (
            <img src={serviceStatusIcon} alt={serviceStatusIcon} />
          ) : (
            t('translation:dashboard:status:statusWidget:status:unknown')
          )}
        </Box>

        <Box>
          <Typography component="div">
            <Box fontSize={20} lineHeight={1.1}>
              {serviceStatus === 1
                ? t('translation:dashboard:status:statusWidget:status:operational')
                : t('translation:dashboard:status:statusWidget:status:maintenance')}
            </Box>
          </Typography>

          <Typography component="div" style={styles.title}>
            <Box fontSize={14} lineHeight={1}>
              {t('translation:dashboard:status:statusWidget:label')}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Paper>
  ) : null;
};

export default ServiceStatusWidget;
