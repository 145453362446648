import React from 'react';
import clsx from 'clsx';
import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { useRouter } from '../../../hooks/useRouter';
import rsaLogo from '../../../assets/rsa-logo.svg';
import gdprLogo from '../../../assets/gdpr-logo.svg';
import sslLogo from '../../../assets/ssl-logo.svg';
import aesLogo from '../../../assets/aes-logo.svg';
import * as ROUTES from '../../../constants/routes';
import capsuleGrayImage from '../../../assets/capsule-gray.png';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '10px',
    border: '1px dashed #D7D9DB',
    height: '100%',

    '&:hover': {
      border: '1px solid transparent',
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
  },
  title: {
    fontSize: 34,
    color: '#D7D9DB',
  },
  securityLogo: {
    width: 110,
    height: 110,
    margin: theme.spacing(0, 1, 0, 1),
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    opacity: 0.9,
  },
  rsa: {
    backgroundImage: `url(${rsaLogo})`,
  },
  gdpr: {
    backgroundImage: `url(${gdprLogo})`,
  },
  ssl: {
    backgroundImage: `url(${sslLogo})`,
  },
  aes: {
    backgroundImage: `url(${aesLogo})`,
  },
}));

const SecurityLogo = (props) => {
  const classes = useStyles();

  return <div className={clsx(classes.securityLogo, classes[props.variant])} />;
};

const CapsuleCardNew = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Box style={{ height: '100%' }}>
      <Card elevation={0} className={classes.card}>
        <CardContent
          onClick={() => {
            router.history.push(ROUTES.USER_CREATE_CAPSULE);
          }}
        >
          <Box textAlign="center" mt={10}>
            <img src={capsuleGrayImage} alt={capsuleGrayImage} />
          </Box>

          <Box mt={8} mb={10} textAlign="center">
            <AddIcon style={{ fontSize: 85, color: '#D7D9DB' }} />
            <Typography className={classes.title}>{t('translation:sidebar:menu:createCapsule')}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <SecurityLogo variant="rsa" />
            <SecurityLogo variant="gdpr" />
            <SecurityLogo variant="ssl" />
            <SecurityLogo variant="aes" />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CapsuleCardNew;
