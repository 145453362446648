import React, { Fragment, useEffect, useState } from 'react';
import { useSteps } from '../../hooks/useSteps';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '../../hooks/useRouter';
import * as ROUTES from '../../constants/routes';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ethereumLogo from '../../assets/ethereum.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin, faEthereum } from '@fortawesome/free-brands-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import QRCode from 'qrcode.react';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Button from '@material-ui/core/Button';
import bitcoinLogo from '../../assets/bitcoin-logo.svg';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useApi } from '../../hooks/useApi';
import TooltipIcon from '../../components/TooltipIcon';
import stripeLogo from '../../assets/stripe.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  secondaryLabel: {
    fontSize: 24,
    color: '#627087',
    marginRight: theme.spacing(2),
  },
  textValue: {
    fontSize: 24,
    fontWeight: 500,
  },
  clipboardButton: {
    color: theme.palette.primary.main,
  },
  qrText: {
    color: '#A6AAB0',
    fontSize: 16,
    marginTop: theme.spacing(1),
    maxWidth: 176,
  },
  actionText: {
    color: '#A6AAB0',
    fontSize: 16,
  },
}));

const PaymentMethodEthereum = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const { INITIAL_STATE, capsule, setCapsule } = useSteps();
  const router = useRouter();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpened(false);
  };

  const doFinish = () => {
    setCapsule(INITIAL_STATE);
    router.history.push(ROUTES.USER_CAPSULES);
  };

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 81 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/prolong/ethereum' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Box mb={4}>
            <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: '24px' }}>
              {t('translation:createCapsule:capsuleEthereumStep:title')} <img src={ethereumLogo} alt={ethereumLogo} />
            </Typography>

            <Box mb={2}>
              <Typography component="span" variant="body1" className={classes.secondaryLabel}>
                {t('translation:createCapsule:capsuleEthereumStep:amountLabel')}
              </Typography>

              <Typography component="span" variant="body1" className={classes.textValue}>
                {capsule.addFunds.toPay} <FontAwesomeIcon icon={faEthereum} />
              </Typography>

              <CopyToClipboard text={capsule.addFunds.toPay} onCopy={() => setSnackbarOpened(true)}>
                <IconButton className={classes.clipboardButton}>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </CopyToClipboard>
            </Box>

            <Box mb={4}>
              <Typography component="p" variant="body1" className={classes.secondaryLabel}>
                {t('translation:createCapsule:capsuleEthereumStep:addressLabel')}
              </Typography>

              <Fragment>
                <Typography component="span" variant="body1" className={classes.textValue}>
                  {'0x' + capsule.addFunds.address}
                </Typography>

                <CopyToClipboard text={`0x${capsule.addFunds.address}`} onCopy={() => setSnackbarOpened(true)}>
                  <IconButton className={classes.clipboardButton}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </CopyToClipboard>

                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  open={snackbarOpened}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert onClose={handleCloseSnackbar} severity="success">
                    Copied to clipboard!
                  </Alert>
                </Snackbar>
              </Fragment>
            </Box>

            <Box mt={2} mb={1}>
              <QRCode value={`0x${capsule.addFunds.address}`} />

              <Typography component="p" variant="body2" className={classes.qrText}>
                {t('translation:createCapsule:capsuleEthereumStep:qrDescription')}
              </Typography>
            </Box>

            <Box mt={4}>
              <Box display="flex" alignItems="center" mb={2}>
                <WarningOutlinedIcon style={{ color: '#FF9800' }} />
                <Box style={{ fontSize: 20, marginLeft: 24 }}>
                  {t('translation:createCapsule:capsuleEthereumStep:warning1')}
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <WarningOutlinedIcon style={{ color: '#FF9800' }} />
                <Box style={{ fontSize: 20, marginLeft: 24 }}>
                  {t('translation:createCapsule:capsuleEthereumStep:warning2')}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={8} display="flex">
            <Box mr={2}>
              <Button variant="outlined" size="large" onClick={props.handleBack}>
                {t('translation:common:back')}
              </Button>
            </Box>

            <Box mr={2}>
              <Button variant="contained" color="primary" size="large" onClick={doFinish}>
                {t('translation:common:done')}
              </Button>
            </Box>

            <Box ml={1} className={classes.actionText}>
              {t('translation:createCapsule:capsuleEthereumStep:actionDescription')}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentMethodBitcoin = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const { INITIAL_STATE, capsule, setCapsule } = useSteps();
  const router = useRouter();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpened(false);
  };

  const doFinish = () => {
    setCapsule(INITIAL_STATE);
    router.history.push(ROUTES.USER_CAPSULES);
  };

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 82 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/prolong/bitcoin' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Box mb={4}>
            <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: '24px' }}>
              {t('translation:createCapsule:capsuleBitcoinStep:title')} <img src={bitcoinLogo} alt={bitcoinLogo} />
            </Typography>

            <Box mb={2}>
              <Typography component="span" variant="body1" className={classes.secondaryLabel}>
                {t('translation:createCapsule:capsuleBitcoinStep:amountLabel')}
              </Typography>

              <Typography component="span" variant="body1" className={classes.textValue}>
                {capsule.addFunds.toPay} <FontAwesomeIcon icon={faBitcoin} />
              </Typography>

              <CopyToClipboard text={capsule.addFunds.toPay} onCopy={() => setSnackbarOpened(true)}>
                <IconButton className={classes.clipboardButton}>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </CopyToClipboard>
            </Box>

            <Box mb={4}>
              <Typography component="p" variant="body1" className={classes.secondaryLabel}>
                {t('translation:createCapsule:capsuleBitcoinStep:addressLabel')}
              </Typography>

              <Fragment>
                <Typography component="span" variant="body1" className={classes.textValue}>
                  {capsule.addFunds.address}
                </Typography>

                <CopyToClipboard text={capsule.addFunds.address} onCopy={() => setSnackbarOpened(true)}>
                  <IconButton className={classes.clipboardButton}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </CopyToClipboard>

                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  open={snackbarOpened}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert onClose={handleCloseSnackbar} severity="success">
                    Copied to clipboard!
                  </Alert>
                </Snackbar>
              </Fragment>
            </Box>

            <Fragment>
              <Box mt={2} mb={1}>
                <QRCode value={capsule.addFunds.address} />

                <Typography component="p" variant="body2" className={classes.qrText}>
                  {t('translation:createCapsule:capsuleBitcoinStep:qrDescription')}
                </Typography>
              </Box>

              <Box mt={4}>
                <Box display="flex" alignItems="center" mb={2}>
                  <WarningOutlinedIcon style={{ color: '#FF9800' }} />
                  <Box style={{ fontSize: 20, marginLeft: 24 }}>
                    {t('translation:createCapsule:capsuleBitcoinStep:warning1')}
                  </Box>
                </Box>

                <Box display="flex" alignItems="center">
                  <WarningOutlinedIcon style={{ color: '#FF9800' }} />
                  <Box style={{ fontSize: 20, marginLeft: 24 }}>
                    {t('translation:createCapsule:capsuleBitcoinStep:warning2')}
                  </Box>
                </Box>
              </Box>
            </Fragment>
          </Box>

          <Box mt={8} display="flex">
            <Box mr={2}>
              <Button variant="outlined" size="large" onClick={props.handleBack}>
                {t('translation:common:back')}
              </Button>
            </Box>

            <Box mr={2}>
              <Button variant="contained" color="primary" size="large" onClick={doFinish}>
                {t('translation:common:done')}
              </Button>
            </Box>

            <Box ml={1} className={classes.actionText}>
              {t('translation:createCapsule:capsuleBitcoinStep:actionDescription')}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentMethodCard = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const api = useApi();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: '#2E3542',
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '24px',
        '::placeholder': {
          color: '#717171',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const capsuleId = router.query.id;

  const handleSubmit = async () => {
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      props.handleNext();
    }
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handlePay = async () => {
    await handleSubmit();
  };

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 83 });
    api
      .requestPayment({
        type: 3,
        amount: Number(capsule.topUpAmount),
        capsuleId,
        months: capsule.price.addedPeriod,
      })
      .then((response) => {
        setCapsule({ ...capsule, requestPayment: response.data });
        setClientSecret(response.data.clientSecret);
      })
      .catch((error) => {
        throw new Error(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/prolong/card' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
            {t('translation:createCapsule:capsuleBankStep:title')}{' '}
            <TooltipIcon title={t('translation:createCapsule:capsuleBankStep:titleTooltip')} placement="top" />
          </Typography>

          <Box mb={2} mt={4}>
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
          </Box>

          <Box textAlign="right" pr={1}>
            <a href="https://stripe.com/" target="_blank" rel="noreferrer">
              <img src={stripeLogo} alt={stripeLogo} height={32} />
            </a>
          </Box>

          <Typography component="div">
            <Box fontSize={16}>
              {error && <div className={classes.error}>{error}</div>}

              {succeeded && (
                <div className={classes.success}>{t('translation:createCapsule:capsuleBankStep:success')}</div>
              )}
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button
              disabled={processing || disabled || succeeded}
              variant="contained"
              color="primary"
              size="large"
              onClick={handlePay}
            >
              {!processing ? t('translation:common:pay') : <CircularProgress color="inherit" size={26} />}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function getPaymentForm(method, props) {
  switch (method) {
    case 'BANK':
      return (
        <Elements stripe={promise}>
          <PaymentMethodCard {...props} />
        </Elements>
      );
    case 'BTC':
      return <PaymentMethodBitcoin {...props} />;
    case 'ETH':
      return <PaymentMethodEthereum {...props} />;
    default:
      return 'Unknown payment method';
  }
}

const ProlongMethod = (props) => {
  const { capsule } = useSteps();

  return getPaymentForm(capsule?.paymentMethod, props);
};

export default ProlongMethod;
