import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  text: {
    color: '#627087',
    fontSize: 12,
  },
}));

const CapsuleProtectionStatus = (props) => {
  const classes = useStyles();
  const { status } = props;
  const { t } = useTranslation();

  switch (status) {
    case 'P':
      return <Box className={classes.text}>{t('translation:capsuleProtectionStatus:P')}</Box>;
    case 'PA':
      return <Box className={classes.text}>{t('translation:capsuleProtectionStatus:PA')}</Box>;
    case 'PE':
      return <Box className={classes.text}>{t('translation:capsuleProtectionStatus:PE')}</Box>;
    default:
      return null;
  }
};

export default CapsuleProtectionStatus;
