import React from 'react';
import img404 from '../assets/404.png';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import * as ROUTES from '../constants/routes';
import Logo from '../components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    padding: theme.spacing(4, 6),
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.header}>
        <RouterLink to={ROUTES.ROOT}>
          <Logo type="standard" />
        </RouterLink>
      </Box>

      <Grid container justify="center">
        <Grid item justify="center">
          <Box display="flex" justifyContent="center">
            <img src={img404} alt={img404} style={{ maxWidth: '50%' }} />
          </Box>

          <Typography component="div">
            <Box textAlign="center">
              THE PAGE YOU ARE TRYING TO OPEN MIGHT HAVE BEEN REMOVED, OR IS TEMPORARILY UNAVAILABLE.
            </Box>
            <Box textAlign="center">
              PLEASE GO BACK TO THE{' '}
              <Link component={RouterLink} to={ROUTES.ROOT} underline="always">
                APP.SAVETOFUTURE.COM
              </Link>{' '}
              WEBSITE AND TRY FROM THERE.
            </Box>
            <Box textAlign="center">GOOD LUCK!</Box>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
