import React from 'react';
import { Box, ButtonBase, Paper, Typography } from '@material-ui/core';

import CapsuleUnpaidImage from '../../assets/capsule-unpaid.png';
import CapsulePaidImage from '../../assets/capsule-paid.png';
import CapsuleOpenedImage from '../../assets/capsule-opened.png';
import CapsuleRemovedImage from '../../assets/capsule-removed.png';
import CapsuleDraftImage from '../../assets/capsule-draft.png';
import { useRouter } from '../../hooks/useRouter';
import * as ROUTES from '../../constants/routes';

const CapsuleImage = (props) => {
  const { status } = props;
  let image;

  switch (status) {
    case 'E':
      image = CapsuleDraftImage;
      break;
    case 'N':
      image = CapsuleUnpaidImage;
      break;
    case 'P':
      image = CapsulePaidImage;
      break;
    case 'PA':
      image = CapsuleOpenedImage;
      break;
    case 'PE':
      image = CapsuleOpenedImage;
      break;
    case 'UD':
      image = CapsuleRemovedImage;
      break;
    case 'ND':
      image = CapsuleRemovedImage;
      break;
    default:
      image = CapsuleDraftImage;
  }

  return <img src={image} alt={image} width={106} />;
};

const CapsulesCountWidget = (props) => {
  const { count, status, title, color } = props;
  const router = useRouter();

  return (
    <Paper
      elevation={1}
      style={{ borderLeft: '9px solid ' + color, height: '100%', width: '100%' }}
      component={ButtonBase}
      onClick={() => router.history.push(ROUTES.USER_CAPSULES)}
    >
      <Box p={3}>
        <Box mb={2}>
          <CapsuleImage status={status} />
        </Box>

        <Typography component="div" style={{ color: color }}>
          <Box fontSize={34} lineHeight={1.1}>
            {count}
          </Box>
        </Typography>

        <Typography component="div">
          <Box fontSize={14} lineHeight={1.1}>
            {title}
          </Box>
        </Typography>
      </Box>
    </Paper>
  );
};

export default CapsulesCountWidget;
