import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import Button from '@material-ui/core/Button';
import SealCapsuleDialog from '../../components/SealCapsuleDialog';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

export const CreateCapsuleStepSettings = (props) => {
  const { capsule, setCapsule, setSealedCapsule } = useSteps();
  const api = useApi();
  const { t } = useTranslation();
  const [isDialogOpened, setDialogOpened] = React.useState(false);
  const [allowDelete, setAllowDelete] = useState(!Boolean(capsule.allowDelete) || false);
  const [allowChange, setAllowChange] = useState(!Boolean(capsule.allowChange) || false);
  const [hideNames, setHideNames] = useState(Boolean(capsule.hideNames) || false);

  const handleChangeAllowDelete = (event) => {
    setAllowDelete(event.target.checked);
  };

  const handleChangeAllowChange = (event) => {
    setAllowChange(event.target.checked);
  };

  const handleChangeHideNames = (event) => {
    setHideNames(event.target.checked);
  };

  const openSealCapsuleDialog = () => {
    setDialogOpened(true);
  };

  const handleSealCapsuleSubmit = () => {
    sealCapsule(capsule).then(() => {
      setDialogOpened(false);
      props.handleNext();
    });
  };

  const sealCapsule = (capsule) => {
    return new Promise((resolve, reject) => {
      api.updateCapsule(capsule).then(() => {
        api.sealCapsule(capsule.capsuleId).then((response) => {
          setSealedCapsule(response.data);
          setCapsule({ ...capsule, status: 'N' });

          resolve('sealed');
        });
      });
    });
  };

  const handleNext = () => {
    openSealCapsuleDialog();
  };

  useEffect(() => {
    const ad = Number(!allowDelete);
    const ac = Number(!allowChange);
    const hn = Number(hideNames);

    setCapsule({ ...capsule, allowDelete: ad, allowChange: ac, hideNames: hn, stepCover: 6 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowDelete, allowChange, hideNames]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/settings' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <SealCapsuleDialog
            onClose={() => {
              setDialogOpened(false);
            }}
            settings={{ allowDelete, allowChange, hideNames }}
            onSubmit={handleSealCapsuleSubmit}
            capsule={capsule}
            open={isDialogOpened}
          />

          <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: 48 }}>
            {t('translation:createCapsule:capsuleSettingsStep:title')}{' '}
            <TooltipIcon title={t('translation:createCapsule:capsuleSettingsStep:titleTooltip')} placement="top" />
          </Typography>

          <FormControl component="fieldset">
            <FormControlLabel
              style={{ marginBottom: 8 }}
              control={<Checkbox color="primary" checked={allowDelete} onChange={handleChangeAllowDelete} />}
              label={
                <Typography style={{ fontSize: 24 }}>
                  {t('translation:createCapsule:capsuleSettingsStep:option1Label')}
                </Typography>
              }
            />

            <FormControlLabel
              style={{ marginBottom: 8 }}
              control={<Checkbox color="primary" checked={allowChange} onChange={handleChangeAllowChange} />}
              label={
                <Typography style={{ fontSize: 24 }}>
                  {t('translation:createCapsule:capsuleSettingsStep:option2Label')}
                </Typography>
              }
            />

            {allowChange && (
              <FormControlLabel
                style={{ marginBottom: 8 }}
                control={<Checkbox color="primary" checked={hideNames} onChange={handleChangeHideNames} />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleSettingsStep:option3Label')}
                  </Typography>
                }
              />
            )}
          </FormControl>

          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button variant="contained" color="primary" size="large" onClick={handleNext}>
              {t('translation:common:doneAndSeal')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
