import React from 'react';
import { Box, Paper, Typography, makeStyles, Grid } from '@material-ui/core';

import logoDashboard from '../../assets/logo-dashboard.png';

const useStyles = makeStyles(() => ({
  message: {
    color: '#FFFFFF',
  },
}));

const ProcessingStatusWidget = (props) => {
  const classes = useStyles();
  const { messageBackground, messageText } = props;

  return (
    <Paper elevation={1} className={classes.message} style={{ backgroundColor: messageBackground }}>
      <Box display="flex" alignItems="center" p={3}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12} md={4}>
            <Box textAlign="center" pt={1} pb={1}>
              <img src={logoDashboard} alt={logoDashboard} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Box>
              <Typography component="div">
                <Box fontSize={26} lineHeight={1.3}>
                  {messageText}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProcessingStatusWidget;
