import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Typography, TextField, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProfileChangeNicknameDialog = (props) => {
  const auth = useAuth();
  const { nickname, open, onClose } = props;
  const { handleSubmit, control } = useForm();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);

    auth.user
      .updateProfile(data)
      .then(function () {
        onClose();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogContent>
        <Box textAlign="center" p={5}>
          <Typography component="div">
            <Box textAlign="center" fontSize={16} fontWeight={500}>
              Change nickname
            </Box>
          </Typography>

          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box mt={4} mb={8} pl={4} pr={4}>
              <Controller
                as={<TextField size="small" fullWidth />}
                name="displayName"
                control={control}
                defaultValue={nickname}
                label="Nickname"
              />
            </Box>

            <Box mt={4} mb={2} display="flex" justifyContent="center">
              <Box mr={2}>
                <Button variant="outlined" size="large" onClick={onClose}>
                  {'Cancel'}
                </Button>
              </Box>

              <Box>
                <Button variant="contained" color="primary" size="large" type="submit">
                  {!isLoading ? 'Save' : <CircularProgress color="inherit" size={26} />}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileChangeNicknameDialog;
