import React, { useEffect, useState } from 'react';
import { Typography, makeStyles, Box, Grid, Container, CircularProgress } from '@material-ui/core';

import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import CapsuleCard from './components/CapsuleCard';
import CapsuleCardNew from './components/CapsuleCardNew';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
}));

const CapsulesList = () => {
  const classes = useStyles();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [capsules, setCapsules] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const onCapsuleDelete = (index, capsuleId) => {
    return new Promise((resolve, reject) => {
      api
        .deleteCapsule(capsuleId)
        .then(() => {
          const updatedCapsule = capsules;
          setLoading(true);
          api
            .getCapsules()
            .then((response) => {
              setCapsules(response.data);
            })
            .catch((error) => {
              throw new Error(error);
            })
            .finally(() => {
              setLoading(false);
            });

          resolve(updatedCapsule);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (auth.isReady) {
      setLoading(true);
      api
        .getCapsules()
        .then((response) => {
          setCapsules(response.data);
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Container className={classes.container} maxWidth="lg">
      <Typography component="div">
        <Box mb={2} fontSize={20} fontWeight={600}>
          {t('translation:capsules:title')}
        </Box>
      </Typography>

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <>
          <Grid container spacing={2} alignItems="stretch">
            {capsules.map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4}>
                <CapsuleCard capsule={item} index={index} onCapsuleDelete={onCapsuleDelete} />
              </Grid>
            ))}

            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <CapsuleCardNew />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default CapsulesList;
