import React, { Fragment } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import Box from '@material-ui/core/Box';
import capsuleFilesImage from '../assets/capsule-files-image.svg';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styleActive = {
  backgroundColor: 'rgba(0,0,0, 0.06)',
  borderRadius: 8,
  border: '1px dashed #999',
};

const styleInactive = {
  borderRadius: 8,
  border: '1px solid #FFF',
};

export const TargetBox = (props) => {
  const { onDrop } = props;
  const { t } = useTranslation();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(props, monitor);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;

  return (
    <div ref={drop} style={isActive ? styleActive : styleInactive}>
      <Box mb={2} mt={4} p={2} textAlign="center">
        <img src={capsuleFilesImage} alt={capsuleFilesImage} />

        <Typography style={{ color: '#A6AAB0', fontSize: 16 }}>
          {isActive ? (
            t('translation:createCapsule:capsuleFilesStep:dropLabel2')
          ) : (
            <Fragment>{t('translation:createCapsule:capsuleFilesStep:dropLabel1')}</Fragment>
          )}
        </Typography>
      </Box>

      {props.children}
    </div>
  );
};
