import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  default: {
    color: '#627087',
    fontSize: 16,
  },
  success: {
    color: '#7EB25D',
    fontSize: 16,
  },
  error: {
    color: '#B00020',
    fontSize: 16,
  },
}));

const CapsuleStatusTitle = (props) => {
  const classes = useStyles();
  const { status } = props;
  const { t } = useTranslation();

  switch (status) {
    case 'E':
      return <Box className={classes.error}>{t('translation:capsuleStatus:E')}</Box>;
    case 'N':
      return <Box className={classes.error}>{t('translation:capsuleStatus:N')}</Box>;
    case 'P':
      return <Box className={classes.success}>{t('translation:capsuleStatus:P')}</Box>;
    case 'PA':
      return <Box className={classes.success}>{t('translation:capsuleStatus:PA')}</Box>;
    case 'PE':
      return <Box className={classes.success}>{t('translation:capsuleStatus:PE')}</Box>;
    case 'UD':
      return <Box className={classes.default}>{t('translation:capsuleStatus:UD')}</Box>;
    case 'ND':
      return <Box className={classes.default}>{t('translation:capsuleStatus:ND')}</Box>;
    default:
      return null;
  }
};

export default CapsuleStatusTitle;
