import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CapsuleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.4757 16.323L8.96943 19.3801C9.15432 19.483 9.39064 19.4221 9.49728 19.2441L15.6515 8.97018C15.7581 8.79216 15.6947 8.56445 15.5098 8.46156L10.0161 5.40448C9.8312 5.30159 9.59487 5.3625 9.48824 5.54052L3.33401 15.8144C3.22737 15.9924 3.29081 16.2201 3.4757 16.323Z"
          stroke="#A6AAB0"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M10.2484 5.02811L15.7421 8.0852C15.927 8.18808 16.1023 8.22905 16.1337 8.1767L17.9433 5.15567C17.9747 5.10333 17.8502 4.97749 17.6653 4.87461L12.1716 1.81752C11.9867 1.71464 11.8114 1.67367 11.7801 1.72601L9.97041 4.74704C9.93905 4.79939 10.0635 4.92523 10.2484 5.02811Z"
          stroke="#A6AAB0"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M1.34698 19.6393L7.03936 22.807C7.23093 22.9136 7.41165 22.9576 7.44301 22.9052L9.25265 19.8842C9.28401 19.8318 9.15413 19.703 8.96255 19.5964L3.27018 16.4288C3.0786 16.3222 2.89789 16.2782 2.86653 16.3305L1.05688 19.3515C1.02553 19.4039 1.15541 19.5327 1.34698 19.6393Z"
          stroke="#A6AAB0"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </SvgIcon>
  );
};

export default CapsuleIcon;
