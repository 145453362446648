import React from 'react';
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import logoTablet from '../assets/logo-tablet.svg';
import logoMobile from '../assets/logo-inverted-minimized.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import * as ROUTES from '../constants/routes';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../hooks/useRouter';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageDialog from './LanguageDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const MobileAppBar = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      router.history.push(ROUTES.AUTH_SIGN_IN);
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={1}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={() => props.toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>

            <Hidden smUp>
              <Box display="flex" alignItems="center">
                <img src={logoMobile} alt={logoMobile} />
              </Box>
            </Hidden>
          </Box>

          <Hidden xsDown>
            <Box display="flex" alignItems="center">
              <img src={logoTablet} alt={logoTablet} />
            </Box>
          </Hidden>

          <Box>
            <IconButton color="inherit" onClick={props.handleActivityToggle}>
              <InfoOutlinedIcon />
            </IconButton>

            <IconButton color="inherit" style={{ marginRight: '-16px' }} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to="/profile">
                <PersonOutlineOutlinedIcon />
                <Box ml={2}>{t('translation:header:profile')}</Box>
              </MenuItem>

              <MenuItem onClick={handleClose}>
                <LanguageDialog language={props.language} onChange={props.handleLanguageChange} device="mobile" />
              </MenuItem>

              <MenuItem onClick={handleSignOut}>
                <ExitToAppIcon />
                <Box ml={2}>{t('translation:header:logout')}</Box>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MobileAppBar;
