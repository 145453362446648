import React from 'react';
import { SvgIcon } from '@material-ui/core';

const DestroyIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.4811 0H7.13632V6.62069H0.515625V32H2.72252V23.1724H3.82597V32H18.1708V29.7931H19.2743V32H21.4811V0ZM7.68804 28.6897H6.58459V26.4828H7.68804V28.6897ZM7.68804 25.3793H6.58459V16.5517H7.68804V25.3793ZM11.5501 30.8966H10.4467V24.2759H11.5501V30.8966ZM15.4122 26.4828H14.3087V24.2759H15.4122V26.4828ZM15.4122 23.1724H14.3087V14.3448H15.4122V23.1724ZM19.2743 28.6897H18.1708V20.4138H19.2743V28.6897Z"
          fill="#2E3542"
        />
      </svg>
    </SvgIcon>
  );
};

export default DestroyIcon;
