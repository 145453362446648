/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import { useTranslation } from 'react-i18next';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  activitySidebar: {
    position: 'relative',
    height: '100vh',
    backgroundColor: '#FFF',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileSidebar: {
    position: 'absolute',
  },
  activitySidebarClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
}));

const ActivitySidebar = (props) => {
  const classes = useStyles();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [log, setLog] = useState([]);

  const activityTypes = {
    1: t('translation:activity:deletedByUser'),
    2: t('translation:activity:deletedByServer'),
    3: t('translation:activity:created'),
    4: t('translation:activity:paid'),
    5: t('translation:activity:prolonged'),
    6: t('translation:activity:balance'),
    7: t('translation:activity:triggered'),
    8: t('translation:activity:files'),
    9: t('translation:activity:courier'),
  };

  const activityIcons = {
    1: () => <DeleteOutlineIcon />,
    2: () => <DeleteOutlineIcon />,
    3: () => <AddIcon />,
    4: () => <PaymentIcon />,
    5: () => <AccessTimeOutlinedIcon />,
    6: () => <AccountBalanceWalletIcon />,
    7: () => <AlarmOnOutlinedIcon />,
    8: () => <InsertDriveFileOutlinedIcon />,
    9: () => <MarkunreadMailboxOutlinedIcon />,
  };

  const init = () => {
    setLoading(true);
    api
      .getActivityLog({ skip: 0, take: 100 })
      .then((response) => {
        setLog(response.data);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (auth.isReady && props.isActivityOpened) {
      init();
    }
  }, [auth, props.isActivityOpened]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={props.isActivityOpened}
      PaperProps={{
        elevation: 1,
      }}
      classes={{
        paper: clsx(
          classes.activitySidebar,
          !props.isActivityOpened && classes.activitySidebarClose,
          props.mobile && classes.mobileSidebar
        ),
      }}
    >
      <div className={classes.appBarSpacer} />

      <Box pt={2} pl={3} pr={3}>
        {isLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {log.length === 0 ? (
              <Typography component="div">
                <Box textAlign="center">No events</Box>
              </Typography>
            ) : (
              log.map((item, index) => (
                <Box key={index}>
                  <Box display="flex" mb={1} mt={1}>
                    <Box mr={3}>
                      <Box>{activityIcons[item.type]}</Box>
                    </Box>

                    <Box>
                      <Box component="span" fontSize={14} fontWeight={600}>
                        {item.name !== undefined ? item.name + ' ' : null}
                      </Box>

                      <Box component="span" fontSize={14}>
                        {activityTypes[item.type]}
                      </Box>

                      <Typography component="div">
                        <Box fontSize={12} color="#A6AAB0">
                          {moment.unix(item.time).format('DD MMM YYYY HH:mm')}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>

                  <Divider light />
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default ActivitySidebar;
