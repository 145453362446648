import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import Button from '@material-ui/core/Button';
import firebase from 'firebase';

/**
 * Capsule creation: Step 4
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateCapsuleStepAction = (props) => {
  const styles = { fontWeight: 600, marginBottom: 48 };
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [actionType, setActionType] = useState(String(capsule.actionType));

  /**
   * Capsule action types:
   *
   * 1 - Send to address
   * 2 - Publish data
   * 3 - Destroy data
   * 5 - Send usb
   *
   * @param event
   */
  const handleChangeActionType = (event) => {
    setActionType(event.target.value);
  };

  useEffect(() => {
    setCapsule({ ...capsule, actionType: Number(actionType), stepCover: 4 });
    // eslint-disable-next-line
  }, [actionType]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/action' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={styles}>
            {t('translation:createCapsule:capsuleActionStep:title')}{' '}
            <TooltipIcon title={t('translation:createCapsule:capsuleActionStep:titleTooltip')} placement="top" />
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup value={actionType} onChange={handleChangeActionType}>
              <FormControlLabel
                style={{ marginBottom: 16 }}
                value={'1'}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleActionStep:option1Label')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginBottom: 16 }}
                value={'5'}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleActionStep:option2Label')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginBottom: 16 }}
                value={'3'}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleActionStep:option3Label')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginBottom: 16 }}
                value={'2'}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleActionStep:option4Label')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button variant="contained" color="primary" size="large" onClick={props.handleNext}>
              {t('translation:common:next')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
