import React from 'react';

import logoStandard from '../assets/logo-standard.svg';
import logoInverted from '../assets/logo-inverted.svg';
import logoInvertedMinimized from '../assets/logo-inverted-minimized.svg';

const Logo = (props) => {
  let logo, height;

  if (props.type === 'standard') {
    logo = logoStandard;
    height = 53;
  } else if (props.type === 'inverted') {
    logo = logoInverted;
    height = 53;
  } else if (props.type === 'inverted-minimized') {
    logo = logoInvertedMinimized;
    height = 43;
  }

  return <img alt={logo} src={logo} height={height} style={{ height: height }} />;
};

export default Logo;
