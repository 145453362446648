import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  success: {
    color: '#80D948',
  },
  error: {
    color: '#B00020',
  },
  icon: {
    marginRight: 4,
  },
  title: {
    fontSize: 14,
  },
}));

const CapsuleStatusPayment = (props) => {
  const classes = useStyles();
  const { status, date } = props;
  const { t } = useTranslation();

  const datePayabilityExpire = moment(new Date(date)).add(10, 'days');
  const daysLeft = datePayabilityExpire.diff(moment(new Date()), 'days');

  switch (status) {
    case 'E':
      return (
        <Fragment>
          <Typography className={classes.title}>{' ' + t('translation:capsuleStatusPayment:E')}</Typography>
        </Fragment>
      );

    case 'N':
      return (
        <Fragment>
          <CancelIcon className={clsx(classes.error, classes.icon)} />
          <Typography className={clsx(classes.title, classes.error)}>
            {' ' + t('translation:capsuleStatusPayment:N1')}
            {' ' + daysLeft}
            {' ' + t('translation:capsuleStatusPayment:N2')}
          </Typography>
        </Fragment>
      );

    case 'P':
      return (
        <Fragment>
          <CheckCircleIcon className={clsx(classes.success, classes.icon)} />
          <Typography className={classes.title}>
            {' ' + t('translation:capsuleStatusPayment:P') + ' ' + date}
          </Typography>
        </Fragment>
      );

    case 'PA':
      return (
        <Fragment>
          <Typography className={classes.title}>{' ' + t('translation:capsuleStatusPayment:PA')}</Typography>
        </Fragment>
      );

    case 'PE':
      return (
        <Fragment>
          <Typography className={classes.title}>{' ' + t('translation:capsuleStatusPayment:PE')}</Typography>
        </Fragment>
      );

    case 'ND':
      return (
        <Fragment>
          <Typography className={classes.title}>{' ' + t('translation:capsuleStatusPayment:ND')}</Typography>
        </Fragment>
      );

    case 'UD':
      return (
        <Fragment>
          <Typography className={classes.title}>{' ' + t('translation:capsuleStatusPayment:UD')}</Typography>
        </Fragment>
      );

    default:
      return null;
  }
};

export default CapsuleStatusPayment;
