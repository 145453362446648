import React from 'react';

import CapsuleUnpaidImage from '../../../assets/capsule-unpaid.png';
import CapsulePaidImage from '../../../assets/capsule-paid.png';
import CapsuleOpenedImage from '../../../assets/capsule-opened.png';
import CapsuleDraftImage from '../../../assets/capsule-draft.png';
import CapsuleRemovedImage from '../../../assets/capsule-removed.png';

const CapsuleStatusImage = (props) => {
  const { status, width, height } = props;
  const style = {
    height,
    width,
  };
  let image;

  switch (status) {
    case 'N':
      image = CapsuleUnpaidImage;
      break;
    case 'P':
      image = CapsulePaidImage;
      break;
    case 'PA':
      image = CapsuleOpenedImage;
      break;
    case 'PE':
      image = CapsuleOpenedImage;
      break;
    case 'UD':
      image = CapsuleRemovedImage;
      break;
    case 'ND':
      image = CapsuleRemovedImage;
      break;
    case 'E':
      image = CapsuleDraftImage;
      break;
    default:
      image = CapsuleDraftImage;
  }

  return <img src={image} alt={image} style={style} />;
};

export default CapsuleStatusImage;
