/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState } from 'react';

import PayMethod from './PayMethod';
import PaySummary from './PaySummary';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import { useSteps } from '../../hooks/useSteps';
import { useRouter } from '../../hooks/useRouter';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  stepper: {
    margin: theme.spacing(1, 0),
  },
  stepperConnectorLine: {
    borderColor: '#D2D9E5',
  },
  actionText: {
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
}));

const getStepContent = (activeStep, handleBack, handleNext) => {
  switch (activeStep) {
    case 0:
      return <PaySummary handleBack={handleBack} handleNext={handleNext} />;
    case 1:
      return <PayMethod handleBack={handleBack} handleNext={handleNext} />;
    default:
      throw new Error('Unknown step');
  }
};

const StepperStyled = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}))(Stepper);

const Pay = () => {
  const router = useRouter();
  const api = useApi();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const auth = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const { setCapsule, INITIAL_STATE } = useSteps();
  // const [data, setData] = useState({ capsule: {}, user: {} });
  const [isEnough, setIsEnough] = useState(null);

  const steps = new Array(isEnough ? 1 : 2).fill('');

  const handleCloseCreateCapsuleLayout = () => {
    setCapsule(INITIAL_STATE);
    router.history.push(ROUTES.USER_CAPSULES);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const capsuleId = router.query.id;

  const getData = async () => {
    const responseCapsule = await api.getCapsule(capsuleId);
    const responseUser = await api.getUser();

    // setData({
    //   capsule: responseCapsule.data,
    //   user: responseUser.data,
    // });

    setIsEnough(responseCapsule.data.capsuleFee + responseCapsule.data.courierFee <= responseUser.data?.balance);

    return {
      capsule: responseCapsule.data,
      user: responseUser.data,
    };
  };

  useEffect(() => {
    if (auth.isReady) {
      getData()
        .then(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  return (
    <Fragment>
      <CssBaseline />

      <Box className={classes.container}>
        {activeStep >= steps.length || isLoading ? null : (
          <Box maxWidth={240}>
            <StepperStyled
              activeStep={activeStep}
              className={classes.stepper}
              connector={
                <StepConnector
                  classes={{
                    line: classes.stepperConnectorLine,
                  }}
                />
              }
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{''}</StepLabel>
                </Step>
              ))}
            </StepperStyled>
          </Box>
        )}

        <Box>
          {activeStep >= steps.length ? (
            <Box>
              <Box textAlign="center" mt={8}>
                <Box>
                  <CheckCircleIcon style={{ fontSize: 40, color: '#80D948' }} />
                  <Typography component="h1" gutterBottom style={{ fontSize: 34, marginTop: 16 }}>
                    {t('translation:createCapsule:paymentSuccess')}
                  </Typography>
                </Box>

                <Box>
                  <Typography gutterBottom style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleHasBeenSaved')}
                  </Typography>
                </Box>

                <Box mt={6}>
                  <Button variant="outlined" size="large" onClick={handleCloseCreateCapsuleLayout}>
                    {t('translation:createCapsule:goToCapsules')}
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>{getStepContent(activeStep, handleBack, handleNext)}</Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default Pay;
