import moment from 'moment';

export const convertBytesToMegaBytes = (value) => {
  return (value / (1024 * 1024)).toFixed(2) + ' Mb';
};

export const convertFromServerDate = (date) => {
  return moment()
    .set('date', date.slice(0, 2))
    .set('month', Number(date.slice(2, 4)) - 1)
    .set('year', date.slice(4, 8))
    .format('DD MMMM yyyy');
};

export const dateToServerFormat = (date) => {
  return moment(new Date(date)).format('DDMMYYYY');
};
