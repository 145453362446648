import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import faqImg from '../../assets/faq-img.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import supportIcon4 from '../../assets/support-icon-4.png';
import faqIcon1 from '../../assets/faq-icon-1.png';
import faqIcon2 from '../../assets/faq-icon-2.png';
import faqIcon3 from '../../assets/faq-icon-3.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(3),
  },
  heading: {
    fontWeight: 600,
  },
}));

const Faq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Box>
          <Box textAlign="center">
            <img src={faqImg} alt={faqImg} width={153} height={153} />
          </Box>

          <Typography component="div">
            <Box fontSize={26} fontWeight={600} textAlign="center">
              {t('translation:faq:title')}
            </Box>
          </Typography>

          <Typography component="div">
            <Box fontSize={16} textAlign="center">
              {t('translation:faq:description')}
            </Box>
          </Typography>
        </Box>
      </Box>

      <Box>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={2} alignItems="center">
              <Box mr={4}>
                <img src={faqIcon1} alt={faqIcon1} />
              </Box>

              <Typography component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:faq:create:title')}
                </Box>
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box>
              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a1')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a2')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a3')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q4')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a4')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q5')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a5')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q6')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a6')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q7')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a7')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q8')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a8')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q9')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a9')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:create:questions:q10')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:create:questions:a10')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={2} alignItems="center">
              <Box mr={4}>
                <img src={faqIcon1} alt={faqIcon1} />
              </Box>

              <Typography component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:faq:storage:title')}
                </Box>
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box>
              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:storage:questions:q1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:storage:questions:a1')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:storage:questions:q2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:storage:questions:a2')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:storage:questions:q3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:storage:questions:a3')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={2} alignItems="center">
              <Box mr={4}>
                <img src={faqIcon2} alt={faqIcon2} />
              </Box>

              <Typography component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:faq:security:title')}
                </Box>
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box>
              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a1')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a2')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a3')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q4')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a4')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q5')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a5')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q6')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a6')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q7')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a7')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:security:questions:q8')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:security:questions:a8')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={2} alignItems="center">
              <Box mr={4}>
                <img src={supportIcon4} alt={supportIcon4} />
              </Box>

              <Typography component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:faq:payments:title')}
                </Box>
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box>
              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:payments:questions:q1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:payments:questions:a1')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:payments:questions:q2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:payments:questions:a2')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:payments:questions:q3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:payments:questions:a3')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" p={2} alignItems="center">
              <Box mr={4}>
                <img src={faqIcon3} alt={faqIcon3} />
              </Box>

              <Typography component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:faq:support:title')}
                </Box>
              </Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box>
              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:support:questions:q1')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:support:questions:a1')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:support:questions:q2')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:support:questions:a2')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion square>
                <AccordionSummary>
                  <Typography className={classes.heading}>{t('translation:faq:support:questions:q3')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{t('translation:faq:support:questions:a3')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default Faq;
