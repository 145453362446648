import React, { Fragment } from 'react';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useRouter } from '../hooks/useRouter';
import Logo from './Logo';
import Box from '@material-ui/core/Box';
import ListItemLink from './ListItemLink';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CapsuleIcon from './CapsuleIcon';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import PolicyIcon from '@material-ui/icons/Policy';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { useTranslation } from 'react-i18next';
import Balance from './Balance';
import UnpaidCapsules from './UnpaidCapsules';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#A6AAB0',
    margin: theme.spacing(0, 1.5),
  },
  divider: {
    margin: theme.spacing(1, 1.5, 1, 1.5),
    backgroundColor: '#4E5768',
  },
  button: {
    backgroundColor: '#E41139',
    margin: theme.spacing(0, 1.5),
    height: theme.spacing(5.5),
    minWidth: theme.spacing(5.5),
    overflow: 'hidden',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '16px 24px 48px 24px',
    ...theme.mixins.toolbar,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 48,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    backgroundColor: '#2E3542',
    width: drawerWidth,
    boxShadow: theme.shadows[6],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
}));

const LeftSidebarMobile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDrawerOpened, setDrawerOpened } = props;
  const router = useRouter();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.isDrawerOpened && classes.drawerPaperClose),
      }}
      PaperProps={{
        elevation: 2,
      }}
      open={props.isDrawerOpened}
    >
      <Toolbar />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box ml={3} mb={3} mt={3}>
            <Box mt={1}>
              <Balance device="mobile" />
            </Box>

            <Box mt={1}>
              <UnpaidCapsules device="mobile" />
            </Box>
          </Box>

          <Divider className={classes.divider} />

          <List className={classes.root}>
            <ListItemLink
              to="/dashboard"
              primary={t('translation:sidebar:menu:dashboard')}
              icon={<DashboardIcon />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to="/capsules"
              primary={t('translation:sidebar:menu:capsules')}
              icon={<CapsuleIcon />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to="/profile"
              primary={t('translation:sidebar:menu:profile')}
              icon={<PermIdentityIcon />}
              setDrawerOpened={setDrawerOpened}
            />
          </List>

          <Divider className={classes.divider} />

          <List className={classes.root}>
            <ListItemLink
              to="/pricing"
              primary={t('translation:sidebar:menu:pricing')}
              icon={<LocalOfferIcon />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink to="/faq" primary="FAQ" icon={<HelpOutlineIcon />} setDrawerOpened={setDrawerOpened} />

            <ListItemLink
              to="/support"
              primary={t('translation:sidebar:menu:support')}
              icon={<HeadsetMicIcon />}
              setDrawerOpened={setDrawerOpened}
            />
          </List>

          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              component={Link}
              className={classes.button}
              to={{
                pathname: '/create-capsule',
                state: { from: router.location.pathname },
              }}
              onClick={() => {
                setDrawerOpened(false);
                window.localStorage.setItem('sidebarOpened', 'false');
              }}
            >
              <AddIcon />
              {isDrawerOpened ? t('translation:sidebar:menu:createCapsule') : null}
            </Button>
          </div>
        </Box>

        <Box mt={6}>
          <List className={classes.root}>
            <ListItemLink
              to="/privacy-policy"
              primary={t('translation:sidebar:menu:privacy')}
              icon={<PolicyIcon />}
              setDrawerOpened={setDrawerOpened}
            />

            <ListItemLink
              to="/terms-of-service"
              primary={t('translation:sidebar:menu:terms')}
              icon={<DescriptionOutlinedIcon />}
              setDrawerOpened={setDrawerOpened}
            />
          </List>
        </Box>

        <Box mt={4}>
          <Typography
            variant="body2"
            component="p"
            style={{
              color: '#FFFFFF',
              margin: '0 28px',
            }}
          >
            {isDrawerOpened ? t('translation:sidebar:version') : null} {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default LeftSidebarMobile;
