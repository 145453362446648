import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';

import iconCardRed from '../../assets/icon-card-red.png';
import iconCardGreen from '../../assets/icon-card-green.png';
import iconBitcoinGreen from '../../assets/icon-bitcoin-green.png';
import iconBitcoinRed from '../../assets/icon-bitcoin-red.png';
import iconEthereumGreen from '../../assets/icon-ethereum-green.png';
import iconEthereumRed from '../../assets/icon-ethereum-red.png';
import { useTranslation } from 'react-i18next';

const MyComponent = (props) => {
  const { cardStatus, bitcoinStatus, ethereumStatus } = props;
  const { t } = useTranslation();

  return (
    <Paper elevation={1} style={{ height: '100%' }}>
      <Box p={4}>
        <Box mb={2} display="flex" alignItems="center">
          <Box mr={2}>
            <img
              src={cardStatus === 1 ? iconCardGreen : iconCardRed}
              alt={cardStatus === 1 ? iconCardGreen : iconCardRed}
            />
          </Box>

          <Box mr={2}>
            <img
              src={bitcoinStatus === 1 ? iconBitcoinGreen : iconBitcoinRed}
              alt={bitcoinStatus === 1 ? iconBitcoinGreen : iconBitcoinRed}
            />
          </Box>

          <Box mr={2}>
            <img
              src={ethereumStatus === 1 ? iconEthereumGreen : iconEthereumRed}
              alt={ethereumStatus === 1 ? iconEthereumGreen : iconEthereumRed}
            />
          </Box>
        </Box>

        <Box>
          <Typography component="div">
            <Box fontSize={20} lineHeight={1.1}>
              {t('translation:dashboard:status:paymentWidget:status')}
            </Box>
          </Typography>

          <Typography component="div" style={{ color: '#A6AAB0' }}>
            <Box fontSize={14} lineHeight={1.1}>
              {t('translation:dashboard:status:paymentWidget:label')}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default MyComponent;
